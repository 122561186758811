<template>
  <div class="table-wrapper">
    <!-- <div class="jump-input" v-if="pagesCount > 1">
      <span class="jump-to-page-title">
        jump to page
      </span>
      <button @click="firstPage">&lt;&lt;</button>
      <button @click="prevPage">&lt;&lt;</button>
      <input
      :value="currentPageNumber"
      @keydown.enter="ChangeCurrentPageNumber"
      >
      <button @click="nextPage">&gt;</button>
      <button @click="lastPage">&gt;&gt;</button>
      from
      {{ pagesCount }}
    </div> -->

    <DxDataGrid
      ref="dataGridRef"
      :data-source="dataSource"
      key-expr="id"
      :columns-auto-width="true"
      :allow-column-resizing="true"
      :on-row-dbl-click="customLink ? getUrl : rowDblClick"
      :row-alternation-enabled="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :show-borders="true"
      :column-hiding-enabled="columnHidingEnabled"
      :remote-operations="{ paging: true, filtering: true, sorting: true }"
      :allow-select-all="true"
      @exporting="onExporting"
      @selection-changed="onSelectionChanged"
      @content-ready="onContentReady"
    >
      <!-- @rowPrepared="onRowPrepared" -->



      <DxExport
      :enabled="true"
      :allow-export-selected-data="true"
    />



      <DxFilterRow
        :visible="true"
        apply-filter="onClick"
      />

      <DxSelection
        mode="multiple"
      />

      <DxHeaderFilter :visable="true" />

      <DxColumn
        v-for="column in columnsTemplate"
        :key="column.label"
        :caption="column.label"
        :data-field="column.data"
        :data-type="column.data_type ? column.data_type : 'string'"
        :visible="column.visible"
        :posix="column.position ? column.position : null"
        :allow-reordering="column.reaorder ? column.reaorder : true"
        :allow-filtering="column.filterable !== undefined ? column.filterable : true"
        :allow-sorting="column.sortable !== undefined ? column.sortable : true"
        :calculate-cell-value="column.calculateCellValue !== undefined ? column.calculateCellValue : (row) => row[column.data] !== undefined ? row[column.data] : ( row[column.firstnode] !== undefined && row[column.firstnode][column.secondnode] !== undefined ) ? row[column.firstnode][column.secondnode] : '-' "
        :false-text="column.falseText"
        :true-text="column.trueText"
        :filter-value="column.filterValue ? column.filterValue : null"
        :sort-order="column.sort ? column.sort : null"
      >

        <DxLookup
          v-if="column.custom_dropdown && column.custom_dropdown.enabled"
          :data-source="column.custom_dropdown.options"
          :display-expr="column.custom_dropdown.display"
          :value-expr="column.custom_dropdown.value"
        />

      </DxColumn>

      <DxColumn
        v-for="customColumnTemplate in customColumnsTemplate"
        :key="customColumnTemplate.data"
        :caption="customColumnTemplate.label"
        :cell-template="customColumnTemplate.custom_template.template_name"
        :data-field="customColumnTemplate.data"
        :data-type="customColumnTemplate.data_type ? customColumnTemplate.data_type : 'string'"
        :visible="customColumnTemplate.visible"
        :posix="customColumnTemplate.position ? customColumnTemplate.position : null"
        :allow-reordering="customColumnTemplate.reaorder ? customColumnTemplate.reaorder : true"
        :allow-filtering="customColumnTemplate.filterable !== undefined ? customColumnTemplate.filterable : true"
        :allow-sorting="customColumnTemplate.sortable !== undefined ? customColumnTemplate.sortable : true"
        :calculate-cell-value="customColumnTemplate.calculateCellValue !== undefined ? customColumnTemplate.calculateCellValue : (row) => row[customColumnTemplate.data] !== undefined ? row[customColumnTemplate.data] : null"
        :false-text="customColumnTemplate.falseText"
        :true-text="customColumnTemplate.trueText"
        :filter-value="customColumnTemplate.filterValue ? customColumnTemplate.filterValue : null"
        :sort-order="customColumnTemplate.sort ? customColumnTemplate.sort : null"
      >

        <DxLookup
          v-if="customColumnTemplate.custom_dropdown && customColumnTemplate.custom_dropdown.enabled"
          :data-source="customColumnTemplate.custom_dropdown.options"
          :display-expr="customColumnTemplate.custom_dropdown.display"
          :value-expr="customColumnTemplate.custom_dropdown.value"
        />
      </DxColumn>

      <template
        v-for="customColumnTemplate in customColumnsTemplate"
        #[customColumnTemplate.custom_template.template_name]="{ data }"
      >
        <div :key="'key-'+customColumnTemplate.data">

          <span
            v-if="tableName == 'rides-list'"
            class="d-flex justify-content-center"
          >

            <span :style="data.data.is_deleted ? 'margin-left:30px':''">
              <span v-if="data.data[customColumnTemplate.data] && data.data[customColumnTemplate.data] != ''">
                <strong v-b-tooltip="customColumnTemplate.custom_template.options[data.data[customColumnTemplate.data].toLowerCase()].tooltip">
                  <div
                    class="m-auto icon"
                    :class="customColumnTemplate.custom_template.options[data.data[customColumnTemplate.data].toLowerCase()].icon"
                  />
                </strong>
              </span>
              <span v-else-if="tableName == 'rides-list' && !data.data[customColumnTemplate.data] || data.data[customColumnTemplate.data] == ''">
                <strong v-b-tooltip="customColumnTemplate.custom_template.options['react'].tooltip">
                  <div
                    class="m-auto icon"
                    :class="customColumnTemplate.custom_template.options['react'].icon"
                  />
                </strong>
              </span>
            </span>

            <span
              v-if="data.data.is_deleted"
              style="margin-top: 5px;"
            >
              <img
                v-b-tooltip="'This ride was deleted by user'"
                src="@/assets/images/icons/ride/deleted-ride.svg"
              >
            </span>

          </span>

          <span v-if="tableName == 'scooters-list' && data.data[customColumnTemplate.data] && customColumnTemplate.custom_template.template_name == 'scooters.odometer'">
            <strong>
              <div class="m-auto">{{ data.data.odometer }}KM</div>
            </strong>
          </span>

          <span v-if="tableName == 'customers-list'">
            <span v-if="data.data[customColumnTemplate.data] && customColumnTemplate.custom_template.template_name == 'customers.store'">
              <div>
                <b-badge
                  v-if="data.data.main_store === 'unknown'"
                  :variant="'primary'"
                >
                  Unknown
                </b-badge>
                <b-badge
                  v-else-if="data.data.main_store === 'usa'"
                  :variant="'secondary'"
                >
                  USA
                </b-badge>
                <b-badge
                  v-else-if="data.data.main_store === 'ca'"
                  :variant="'info'"
                >
                  Canada
                </b-badge>
                <b-badge
                  v-else-if="data.data.main_store === 'subs'"
                  :variant="'danger'"
                >
                  Subscription
                </b-badge>
                <b-badge
                  v-else-if="data.data.main_store === 'app'"
                  :variant="'warning'"
                >
                  App User
                </b-badge>
              </div>

            </span>

            <span v-if="customColumnTemplate.custom_template.template_name == 'customers.platform'">
              <div>
                <b-badge
                  v-if="data.data.platform === 'None'"
                  :variant="'primary'"
                >
                  None
                </b-badge>
                <b-badge
                  v-else-if="data.data.platform === 'React'"
                  :variant="'secondary'"
                >
                  React
                </b-badge>
                <b-badge
                  v-else-if="data.data.platform === 'IOS'"
                  :variant="'info'"
                >
                  IOS
                </b-badge>
                <b-badge
                  v-else-if="data.data.platform === 'Android'"
                  :variant="'danger'"
                >
                  Android
                </b-badge>
              </div>

            </span>

            <span v-if="customColumnTemplate.custom_template.template_name == 'customers.country'">
              <strong>
                <div class="m-auto">{{ data.data.country_alias }}</div>
              </strong>
            </span>

          </span>

          <span v-if="tableName == 'city-customers-list'">
            <span v-if="customColumnTemplate.custom_template.template_name == 'city.customers.actions'">
              <div class="text-center">
                <feather-icon
                  v-b-tooltip="'Edit'"
                  icon="Edit2Icon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('edit',data.data)"
                />
              </div>
            </span>
          </span>

          <span v-if="tableName == 'admins-logs-list'">

            <span v-if="customColumnTemplate.custom_template.template_name == 'admins.logs.model'">
              <div class="text-center">
                <router-link
                  v-if="data.data.type"
                  v-b-tooltip="'View '+capitalizeFirstLetter(data.data.type)"
                  class="pointer-cursor"
                  :to="{ name: routes[data.data.type] , params: { id: data.data.model_id }}"
                >
                  <b>{{ capitalizeFirstLetter(data.data.type) }}</b>
                </router-link>
                <div v-else>
                  -
                </div>
              </div>
            </span>

            <span v-if="customColumnTemplate.custom_template.template_name == 'admins.logs.created_at'">
              <div class="text-center">
                <div>
                  <b>{{ moment.utc(data.data.created_at * 1000).local().format('ll hh:mma') }}</b>
                </div>
              </div>
            </span>

            <span v-if="customColumnTemplate.custom_template.template_name == 'admins.logs.actions'">
              <div class="text-center">
                <feather-icon
                  v-b-tooltip="'Edit'"
                  icon="Edit2Icon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('edit',data.data)"
                />
              </div>
            </span>

          </span>

          <span v-if="tableName == 'targets-list'">
            <span v-if="customColumnTemplate.custom_template.template_name == 'targets.list.actions'">
              <div class="text-center">
                <feather-icon
                  v-b-tooltip="'Edit'"
                  icon="Edit2Icon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('edit',data.data)"
                />
              </div>
            </span>
          </span>

          <span v-if="tableName == 'admins-users-list'">
            <span v-if="customColumnTemplate.custom_template.template_name == 'admins.users.list.actions'">
              <div class="text-center">

                <feather-icon
                  v-if="$ableTo('Edit Admin')"
                  v-b-tooltip="'Edit'"
                  icon="Edit2Icon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('edit',data.data)"
                />

                <feather-icon
                  v-if="$ableTo('Change Admin Password')"
                  v-b-tooltip="'Send reset password email'"
                  icon="SendIcon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('resetPassword',data.data)"
                />

                <feather-icon
                  v-if="$ableTo('Deactivate Admin')"
                  v-b-tooltip="'Deactivate'"
                  icon="XIcon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('toggle',data.data)"
                />

                <feather-icon
                  v-if="$ableTo('Delete Admin')"
                  v-b-tooltip="'Delete'"
                  icon="TrashIcon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('remove',data.data)"
                />

              </div>
            </span>
          </span>

          <span v-if="tableName == 'admins-roles-list'">
            <span v-if="customColumnTemplate.custom_template.template_name == 'admin.roles.list.actions'">
              <div class="text-center">

                <feather-icon
                  v-if="$ableTo('Edit Role')"
                  v-b-tooltip="'Edit Role'"
                  icon="EditIcon"
                  class="mr-50 pointer-cursor"
                  @click="() => $emit('edit',data.data)"
                />

              </div>
            </span>
          </span>


          <span v-if="tableName == 'pro-testers-list'">
            <span v-if="data.data[customColumnTemplate.data] && customColumnTemplate.custom_template.template_name == 'protesters.store'">
              <div>
                <b-badge
                  v-if="data.data.main_store === 'unknown'"
                  :variant="'primary'"
                >
                  Unknown
                </b-badge>
                <b-badge
                  v-else-if="data.data.main_store === 'usa'"
                  :variant="'secondary'"
                >
                  USA
                </b-badge>
                <b-badge
                  v-else-if="data.data.main_store === 'ca'"
                  :variant="'info'"
                >
                  Canada
                </b-badge>
                <b-badge
                  v-else-if="data.data.main_store === 'subs'"
                  :variant="'danger'"
                >
                  Subscription
                </b-badge>
                <b-badge
                  v-else-if="data.data.main_store === 'app'"
                  :variant="'warning'"
                >
                  App User
                </b-badge>
              </div>
              </span>


              <span v-if="data.data[customColumnTemplate.data] && customColumnTemplate.custom_template.template_name == 'protesters.platform'">
                <div>
                <b-badge
                  v-if="data.data.platform === 'None'"
                  :variant="'primary'"
                >
                  None
                </b-badge>
                <b-badge
                  v-else-if="data.data.platform === 'React'"
                  :variant="'secondary'"
                >
                  React
                </b-badge>
                <b-badge
                  v-else-if="data.data.platform === 'IOS'"
                  :variant="'info'"
                >
                  IOS
                </b-badge>
                <b-badge
                  v-else-if="data.data.platform === 'Android'"
                  :variant="'danger'"
                >
                  Android
                </b-badge>
              </div>

            </span>


            <span v-if="data.data[customColumnTemplate.data] && customColumnTemplate.custom_template.template_name == 'protesters.status'">
                <div>
                <b-badge
                  v-if="data.data.status === 'active'"
                  :variant="'success'"
                >
                  Active
                </b-badge>
                <b-badge
                  v-else
                  :variant="'danger'"
                >
                  Blocked
                </b-badge>
              </div>

            </span>

          </span>









        </div>
      </template>

      <DxPager
        :show-page-size-selector="false"
         display-mode="compact"
        :allowed-page-sizes="[5, 20, 50]"
      />

      <!-- <DxPaging
        :page-size="pagesize"
        :page-index="pageIndex"
      /> -->

    </DxDataGrid>
  </div>

</template>
<script>

import {
  DxDataGrid,
  DxFilterRow,
  DxSelection,
  DxHeaderFilter,
  DxSearchPanel,
  DxExport,
  DxPaging,
  DxColumn,
  DxRemoteOperations,
  DxPager,
  DxLookup,
  DxToolbar,
  DxItem

} from 'devextreme-vue/data-grid'
import DxCheckBox from 'devextreme-vue/check-box'
import { VBTooltip } from 'bootstrap-vue'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import Moment from 'moment'

export default {
  components: {
    DxDataGrid,
    DxFilterRow,
    DxCheckBox,
    DxSelection,
    DxHeaderFilter,
    DxSearchPanel,
    DxExport,
    DxPaging,
    DxColumn,
    Workbook,
    DxRemoteOperations,
    DxPager,
    DxLookup,
    DxToolbar,
    DxItem

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    dataSource: {
      type: Object,
      default() {
        return null
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    routerLink: {
      type: String,
      default() {
        return ''
      },
    },
    tableName: {
      type: String,
      default() {
        return ''
      },
    },
    exportFileName: {
      type: String,
      default() {
        return `sheet-${Moment(new Date()).format('YYYY-MM-DD')}`
      },
    },

    customLink: {
      type: Boolean,
      default() {
        return false
      },
    },
    cutomFunction: {
      type: String,
      default() {
        return null
      },
    },
    store: {
      type: String,
      default() {
        return null
      },
    },
    primaryKey: {
      type: Boolean,
      default() {
        return true
      },
    },

    routes: {
      type: Object,
      default() {
        return null
      },
    },

    pagesize: {
      type: Number,
      default() {
        return 20
      },
    },

    filterValue: {
      type: Array,
      default() {
        return []
      },
    },

  },
  data() {
    return {
      moment: Moment,
      selectedRows: [],
      selectedRowsData: [],
      customColumnsTemplate: [],
      columnsTemplate: [],
      columnHidingEnabled: false,
      currentPageIndex: 0,
      currentPageNumber: 0,
      pageIndex: 0,
      pagesCount: 0,
    }
  },
  mounted() {
    this.dataGrid = this.$refs.dataGridRef.instance
    this.customColumnsTemplate = this.columns.filter(column => column.custom_template && column.custom_template.template_name.trim() !== '')
    this.columnsTemplate = this.columns.filter(column => !column.custom_template || column.custom_template.template_name.trim() === '')
    this.columnHidingEnabled = window.innerWidth < 1000
    window.addEventListener('resize', this.enableDisableHiding)
  },
  methods: {
    filterData(e) {
      const isInput =  e.srcElement?.classList.contains('dx-texteditor-input')
      const multiTablePages = ['pro-testers-list','customers-list']
      if(e.keyCode == 13 && isInput) {

        if(multiTablePages.includes(this.tableName)) {
          const filterWrapper =  document.querySelectorAll(".tab-content .active .table-wrapper .dx-apply-button")[0]
          filterWrapper.click()
        }else {
          const filterWrapper =  document.querySelectorAll(".table-wrapper .dx-apply-button")[0]
          filterWrapper.click()
        }
      }
    },
    nextPage(){
      this.pageIndex = this.pageIndex <  (this.dataGrid.pageCount() - 1) ? this.pageIndex + 1 : this.dataGrid.pageCount() - 1;
      this.currentPageNumber = this.pageIndex + 1
    },
    prevPage(){
      this.pageIndex = this.pageIndex <=  0 ? 0 : this.pageIndex - 1;
      this.currentPageNumber = this.pageIndex + 1
    },
    lastPage() {
      this.pageIndex = this.dataGrid.pageCount() - 1
    },
    firstPage() {
      this.pageIndex = 0
    },
    ChangeCurrentPageNumber(input) {
      const CurrentNum = input.target.value
      this.currentPageNumber = CurrentNum
      this.pageIndex = CurrentNum > 0 ?  (CurrentNum - 1) : 0
    },
    onContentReady() {
      // this.dataGrid = this.$refs.dataGridRef.instance
      // this.currentPageNumber = this.dataGrid.pageIndex() + 1
      // this.pagesCount = this.dataGrid.pageCount()
      this.getActiveTable();
    },
    getActiveTable() {
      let tables = Array.from(document.getElementsByClassName("table-wrapper"))
      if(tables) {
        tables.forEach(table => {
          table.addEventListener('keyup', this.filterData)
        });
      }
    },
    enableDisableHiding() {
      this.columnHidingEnabled = window.innerWidth < 1000
    },
    getUrl(row) {
      const id = row.data.uuid
      switch (this.store) {
        case 'subs':
          window.open(`https://rideapollo.myshopify.com/admin/orders/${id}`)
          break
        case 'usa':
          window.open(`https://apollo-mvmt.myshopify.com/admin/orders/${id}`)
          break
        case 'ca':
          window.open(`https://apollo-scooters-canada.myshopify.com/admin/orders/${id}`)
          break
      }
    },
    searchColumns(event) {
      console.log(event)
    },
    rowDblClick(row) {
      localStorage.setItem('selectedRow', row.loadIndex+1)
      if (row.data.id === undefined || row.data.id === '' || this.routerLink === '') {
        return false
      }
      this.$router.push({ name: this.routerLink, params: { id: row.data.id }})
    },
    onExporting(e) {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet(this.exportFileName)
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        selectedRowsOnly: e.selectedRowsOnly,
        customizeCell: ({ gridCell, excelCell }) => {
          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'device') {
              excelCell.value = gridCell.value ? gridCell.value : 'React'
            }

            if (gridCell.column.dataField === 'is_app_user') {
              excelCell.value = gridCell.value ? 'Yes' : 'No'
            }
          }
        },

      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${this.exportFileName}.xlsx`)
        })
      })
      e.cancel = true
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    onSelectionChanged() {
      this.selectedRowsData = this.dataGrid.getSelectedRowsData()
    },

  },
}

</script>

<style lang="scss">

    .dx-toolbar-item {
      .dx-apply-button {
        display: none;
      }
    }


     .dx-datagrid-content .dx-datagrid-table .dx-row > td{
        vertical-align: middle !important;
      }
      .pointer-cursor {
        cursor:pointer;
      }

      .table-wrapper {
        position: relative;
      .jump-input {
          position: absolute;
          right: 75px;
          top: 5px;
          z-index: 1;
          text-align: right;
          text-transform: capitalize;
        input {
          text-align: center;
          width: 50px;
        }
          button {
            border: 0;
            padding: 3px 5px;
            color:#333;
            margin-right: 2px;
            margin-left: 2px;
          }
      }
      }



      @media screen and (max-width: 512px) {
      .table-wrapper {
        .jump-input {
          padding-bottom: 20px !important;
          .jump-to-page-title {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: 335px) {
      .table-wrapper {
        .jump-input {
          .jump-to-page-title {
            display: none;
          }
        }
      }
    }



</style>
